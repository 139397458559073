import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    ordersLoading: false,
  },
  reducers: {
    getOrdersRequest: (state) => {
      state.ordersLoading = true;
    },
    getOrdersSuccess: (state, actions) => {
      state.orders = actions.payload;
      state.ordersLoading = false;
    },
    getOrdersFailed: (state) => {
      state.ordersLoading = false;
    },
  },
});

export const { setScanBarCode } = orderSlice.actions;

export default orderSlice.reducer;
