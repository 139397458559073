import { Button } from "antd";
import React from "react";

function CollectionsTopPage({ setToggleModal }) {
  return (
    <div className="flex justify-between p-4">
      <h1 className="text-2xl font-bold">Toutes nos collections</h1>
      <Button
        type="primary"
        onClick={() =>
          setToggleModal({
            active: true,
            collection: null,
          })
        }
      >
        Ajouter une collection
      </Button>
    </div>
  );
}

export default CollectionsTopPage;
