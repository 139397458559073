import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addArticle,
  addArticleImages,
  getArticle,
  updateArticle,
} from "../../../redux/actions/articleActions";
import { LoadingScreen } from "../../organisms";

function ArticleTemplate() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imagesUrl, setImagesUrl] = useState([]);
  const { scanBarCode, refreshArticle } = useSelector((state) => state.scan);
  const { collections } = useSelector((state) => state.collection);
  const { colors } = useSelector((state) => state.color);
  const { token } = useSelector((state) => state.admin);
  const [id, setId] = useState(null);
  const [form] = Form.useForm();
  const { barCode } = useParams();
  const initialized = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (scanBarCode === barCode) {
      setLoading(true);
      getArticle(token, barCode)
        .then((res) => {
          setImagesUrl(res.data.images);
          setId(res.data._id);
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data?.model?.name,
            price: res.data.price,
            wholesalePrice: res.data?.wholesalePrice,
            barCode: res.data.barCode,
            quantity: res.data.quantity,
            color: res.data.color._id,
            secondColor: res.data?.secondColor?._id,
            matter: res.data.matter,
            length: res.data.size.length,
            width: res.data.size.width,
            crossOutPrice: res.data.crossOutPrice,
            weight: res.data.weight,
            articleCollection: res.data?.articleCollection?._id,
            location: res.data?.location,
          };
          form.setFieldsValue(fields);
          setLoading(false);
        })
        .catch((err) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            barCode: barCode,
          };
          form.setFieldsValue(fields);
          toast(err.response.data.message, { type: "error" });
          setLoading(false);
        });
    } // eslint-disable-next-line
  }, [refreshArticle]);

  useEffect(() => {
    if (barCode) {
      if (!initialized.current) {
        setLoading(true);
        getArticle(token, barCode)
          .then((res) => {
            setImagesUrl(res.data.images);
            setId(res.data._id);
            let fields = form.getFieldsValue();
            fields = {
              ...fields,
              name: res.data?.model?.name,
              price: res.data.price,
              wholesalePrice: res.data?.wholesalePrice,
              barCode: res.data.barCode,
              quantity: res.data.quantity,
              color: res.data.color._id,
              secondColor: res.data?.secondColor?._id,
              matter: res.data.matter,
              length: res.data.size.length,
              width: res.data.size.width,
              crossOutPrice: res.data.crossOutPrice,
              weight: res.data.weight,
              articleCollection: res.data?.articleCollection?._id,
              location: res.data?.location,
            };
            form.setFieldsValue(fields);
            setLoading(false);
          })
          .catch((err) => {
            setImagesUrl([]);
            setId(null);
            let fields = form.getFieldsValue();
            fields = {
              ...fields,
              barCode: barCode,
              name: "",
              price: "",
              wholesalePrice: "",
              quantity: "",
              color: "",
              secondColor: "",
              matter: "",
              length: "",
              width: "",
              articleCollection: "",
              location: "",
            };
            form.setFieldsValue(fields);
            toast(err.response.data.message, { type: "error" });
            setLoading(false);
          });
        initialized.current = true;
      }
    } // eslint-disable-next-line
  }, [barCode, form, initialized]);

  useEffect(() => {
    if (initialized.current) {
      setTimeout(() => {
        initialized.current = false;
      }, 500);
    } // eslint-disable-next-line
  }, [initialized.current]);

  const onSelectImages = (data) => {
    const images = data.target.files;
    const formData = new FormData();

    for (const image of images) {
      formData.append("images", image);
    }
    addArticleImages(token, formData)
      .then((res) => setImagesUrl((prevState) => [...prevState, ...res.data]))
      .catch((err) => console.log(err));
  };
  const onAddArticle = (values) => {
    let body = {
      name: values.name,
      price: values.price,
      wholesalePrice: values.wholesalePrice,
      quantity: values.quantity,
      color: values.color,
      secondColor: values.secondColor,
      matter: values.matter,
      length: values.length,
      width: values.width,
      crossOutPrice: values.crossOutPrice,
      weight: values.weight,
      articleCollection: values.articleCollection,
      images: imagesUrl,
      location: values.location,
    };
    console.log(body);
    const goToArticle = () => navigate("/articles");
    if (id) {
      updateArticle(token, barCode, body, goToArticle, dispatch);
    } else {
      body = {
        ...body,
        barCode: values.barCode,
      };
      addArticle(token, body, goToArticle, dispatch);
    }
  };
  return (
    <div className="max-w-[1440px] m-auto p-4">
      {loading && <LoadingScreen />}
      <h1 className="text-2xl font-bold border p-4">
        {id ? "Modifier l'article" : "Ajouter un article"}
      </h1>
      <div className="border p-4">
        <Form
          layout="vertical"
          onFinish={onAddArticle}
          variant={"filled"}
          form={form}
          initialValues={{ collection: "" }}
        >
          <Row gutter={[20, 20]}>
            <Col span={6}>
              <Form.Item
                label="Code barre"
                name="barCode"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner le numéro de code barre!",
                  },
                  {
                    pattern: /^8/,
                    message: "Le code-barre doit commencer par 8!",
                  },
                ]}
              >
                <Input placeholder="013656" disabled={id} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Nom de l'article"
                name="name"
                /*rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner le nom de l'article!",
                  },
                ]}*/
              >
                <Input placeholder="Tapis oriental ROYAL" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Collection" name="articleCollection">
                <Select>
                  <Select.Option value="">
                    <span className="text-[#c1c1c1]">
                      --Selectionnez la collection--
                    </span>
                  </Select.Option>
                  {collections.map((collection) => (
                    <Select.Option value={collection._id}>
                      {collection.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Prix (en €)"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner le prix de l'article!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="29.99"
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Prix barré (en €)" name="crossOutPrice">
                <InputNumber
                  placeholder="29.99"
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Prix grossiste (en €)"
                name="wholesalePrice"
                rules={[
                  {
                    required: true,
                    message:
                      "Veuillez renseigner le prix grossiste de l'article!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="29.99"
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Quantité"
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner la quantité!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="10"
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Couleur"
                name="color"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner la couleur de l'article!",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="">
                    <span className="text-[#c1c1c1]">
                      --Selectionnez la couleurs--
                    </span>
                  </Select.Option>
                  {colors.map((color) => (
                    <Select.Option value={color._id}>
                      <div className="flex items-center">
                        <span
                          className={`h-[20px] w-[20px] border mr-2 rounded`}
                          style={{ backgroundColor: color.hex }}
                        />
                        {color.name}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Couleur Secondaire"
                name="secondColor"
                /*rules={[
                  {
                    required: true,
                    message:
                      "Veuillez sélectionner la couleur secondaire de l'article!",
                  },
                ]}*/
              >
                <Select>
                  <Select.Option value="">
                    <span className="text-[#c1c1c1]">
                      --Selectionnez la couleurs--
                    </span>
                  </Select.Option>
                  {colors.map((color) => (
                    <Select.Option value={color._id}>
                      <div className="flex items-center">
                        <span
                          className={`h-[20px] w-[20px] border mr-2 rounded`}
                          style={{ backgroundColor: color.hex }}
                        />
                        {color.name}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Matière" name="matter">
                <Input placeholder="Soie" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Longueur (en cm)"
                name="length"
                placeholder="300"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner la longueur de l'article!",
                  },
                ]}
              >
                <InputNumber placeholder="300" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Largeur (en cm)"
                name="width"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner la largeur de l'article!",
                  },
                ]}
              >
                <InputNumber placeholder="300" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Poids (en kg)"
                name="weight"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner le poids de l'article!",
                  },
                ]}
              >
                <InputNumber placeholder="0.3" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Emplacement" name="location">
                <Input placeholder="A4" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex flex-wrap mb-10">
            <div className="p-1">
              <label
                htmlFor="image-selector"
                className="border w-[270px] h-[180px] flex justify-center items-center"
              >
                <div className="text-6xl text-[lightgray]">+</div>
              </label>
              <input
                type="file"
                style={{ display: "none" }}
                id="image-selector"
                multiple
                onChange={onSelectImages}
              />
            </div>
            {imagesUrl.map((image, i) => (
              <div className="p-1" key={image}>
                <img
                  alt={`article-${i}`}
                  className="border w-[270px] h-[180px] flex justify-center items-center object-cover object-center"
                  src={image}
                />
              </div>
            ))}
          </div>
          <Form.Item label={null}>
            <Button
              size="large"
              htmlType="submit"
              type="primary"
              style={{ width: 300 }}
            >
              {id ? "Modifier l'article" : "Ajouter l'article"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ArticleTemplate;
