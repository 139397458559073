import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteArticle } from "../../../redux/actions/articleActions";

const ArticleDeleteModal = ({ toggleModal, onCloseModal }) => {
  const { token } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  return (
    <Modal open={toggleModal.active} onCancel={onCloseModal} footer={null}>
      <h2 className="font-bold text-xl mb-6">Supprimer l'article</h2>
      <p>
        Êtes-vous sûr de bien vouloir supprimer l'article{" "}
        {toggleModal?.article?.name}
      </p>
      <div className="mt-6 flex justify-end">
        <Button style={{ marginRight: 10 }} onClick={onCloseModal}>
          Annuler
        </Button>
        <Button
          type="primary"
          danger
          onClick={() =>
            deleteArticle(
              token,
              toggleModal?.article?._id,
              onCloseModal,
              dispatch
            )
          }
        >
          Supprimer
        </Button>
      </div>
    </Modal>
  );
};

export default ArticleDeleteModal;
