import React from "react";
import { ProfileInformations } from "../../organisms";

const ProfileTemplate = () => {
  return (
    <div className="max-w-[1440px] m-auto p-4">
      <div className="flex justify-between p-4">
        <h1 className="text-2xl font-bold">Mon Profil</h1>
      </div>
      <div>
        <ProfileInformations />
      </div>
    </div>
  );
};

export default ProfileTemplate;
