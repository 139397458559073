import { Button, Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAdmin } from "../../../redux/actions/adminActions";
import { LoadingScreen } from "../../organisms";

const LoginTemplate = () => {
  const dispatch = useDispatch();
  const { adminLoading } = useSelector((state) => state.admin);
  const onLoginAdmin = (values) => {
    loginAdmin(values, dispatch);
  };
  return (
    <div className="max-w-[1440px] m-auto flex justify-center items-center h-[100vh]">
      {adminLoading && <LoadingScreen />}
      <div className="w-[500px] border p-6 rounded-xl">
        <Form layout="vertical" onFinish={onLoginAdmin} variant={"filled"}>
          <Form.Item
            label="Adresse Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre adresse e-mail!",
              },
              {
                type: "email",
                message: "Le format de l'e-mail est incorrect!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mot de passe"
            name="password"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label={null}>
            <Button
              htmlType="submit"
              type={adminLoading ? "default" : "primary"}
              size="large"
              style={{ width: "100%", marginTop: 30 }}
            >
              Se connecter
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginTemplate;
