import { createSlice } from "@reduxjs/toolkit";

export const articleSlice = createSlice({
  name: "article",
  initialState: {
    articles: [],
    articlesLoading: false,
    articleLoading: false,
  },
  reducers: {
    getArticlesRequest: (state) => {
      state.articlesLoading = true;
    },
    getArticlesSuccess: (state, actions) => {
      state.articles = actions.payload;
      state.articlesLoading = false;
    },
    getArticlesFailed: (state) => {
      state.articlesLoading = false;
    },
    updateArticleRequest: (state) => {
      state.articleLoading = true;
    },
    updateArticleSuccess: (state) => {
      state.articleLoading = false;
    },
    updateArticleFailed: (state) => {
      state.articleLoading = false;
    },
  },
});

export const {
  getArticlesRequest,
  getArticlesSuccess,
  getArticlesFailed,
  updateArticleRequest,
  updateArticleSuccess,
  updateArticleFailed,
} = articleSlice.actions;

export default articleSlice.reducer;
