import { createSlice } from "@reduxjs/toolkit";

export const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    collections: [],
    collectionsLoading: false,
    collectionLoading: false,
  },
  reducers: {
    getCollectionsRequest: (state) => {
      state.collectionsLoading = true;
    },
    getCollectionsSuccess: (state, actions) => {
      state.collections = actions.payload;
      state.collectionsLoading = false;
    },
    getCollectionsfailed: (state) => {
      state.collectionsLoading = false;
    },
    updateCollectionRequest: (state) => {
      state.collectionLoading = true;
    },
    updateCollectionSuccess: (state, actions) => {
      state.collectionLoading = false;
    },
    updateCollectionfailed: (state) => {
      state.collectionLoading = false;
    },
  },
});

export const {
  getCollectionsRequest,
  getCollectionsSuccess,
  getCollectionsfailed,
  updateCollectionRequest,
  updateCollectionSuccess,
  updateCollectionfailed,
} = collectionSlice.actions;

export default collectionSlice.reducer;
