import React, { useState } from "react";
import { ArticleDeleteModal } from "../../mollecules";
import { ArticlesList, ArticlesTopPage } from "../../organisms";

function ArticlesTemplate() {
  const [toggleModal, setToggleModal] = useState({
    active: false,
    article: null,
  });
  const onCloseModal = () => {
    setToggleModal({
      active: false,
      article: null,
    });
  };
  return (
    <div className="max-w-[1440px] m-auto p-4">
      <ArticleDeleteModal
        toggleModal={toggleModal}
        onCloseModal={onCloseModal}
      />
      <ArticlesTopPage />
      <ArticlesList setToggleModal={setToggleModal} />
    </div>
  );
}

export default ArticlesTemplate;
