import axios from "axios";
import { toast } from "react-toastify";
import { COLLECTIONS_URL } from "../api/api";
import {
  getCollectionsfailed,
  getCollectionsRequest,
  getCollectionsSuccess,
  updateCollectionfailed,
  updateCollectionRequest,
  updateCollectionSuccess,
} from "../reducers/collectionReducer";

export const getCollections = (token, dispatch) => {
  dispatch(getCollectionsRequest());
  axios
    .get(COLLECTIONS_URL, { headers: { "auth-token": token } })
    .then((res) => {
      dispatch(getCollectionsSuccess(res.data));
    })
    .catch(() => {
      dispatch(getCollectionsfailed());
    });
};

export const addCollection = (token, body, onCloseModal, dispatch) => {
  dispatch(updateCollectionRequest());
  axios
    .post(COLLECTIONS_URL, body, { headers: { "auth-token": token } })
    .then((res) => {
      onCloseModal();
      dispatch(updateCollectionSuccess());
      getCollections(token, dispatch);
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      dispatch(updateCollectionfailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const updateCollection = (token, id, body, onCloseModal, dispatch) => {
  dispatch(updateCollectionRequest());
  axios
    .put(`${COLLECTIONS_URL}/${id}`, body, { headers: { "auth-token": token } })
    .then((res) => {
      onCloseModal();
      dispatch(updateCollectionSuccess());
      getCollections(token, dispatch);
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      dispatch(updateCollectionfailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const deleteCollection = (token, id, onCloseDeleteModal, dispatch) => {
  console.log(token);
  dispatch(updateCollectionRequest());
  axios
    .delete(`${COLLECTIONS_URL}/${id}`, {
      headers: { "auth-token": token },
    })
    .then((res) => {
      onCloseDeleteModal();
      dispatch(updateCollectionSuccess());
      getCollections(token, dispatch);
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      dispatch(updateCollectionfailed());
      toast(err.response.data.message, { type: "error" });
    });
};
