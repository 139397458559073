import axios from "axios";
import { toast } from "react-toastify";
import { COLORS_URL } from "../api/api";
import {
  getColorsfailed,
  getColorsRequest,
  getColorsSuccess,
  updateColorfailed,
  updateColorRequest,
  updateColorSuccess,
} from "../reducers/colorReducer";

export const getColors = (token, dispatch) => {
  dispatch(getColorsRequest());
  axios
    .get(COLORS_URL, { headers: { "auth-token": token } })
    .then((res) => {
      dispatch(getColorsSuccess(res.data));
    })
    .catch(() => {
      dispatch(getColorsfailed());
    });
};

export const addColor = (token, body, onCloseModal, dispatch) => {
  dispatch(updateColorRequest());
  axios
    .post(COLORS_URL, body, { headers: { "auth-token": token } })
    .then((res) => {
      onCloseModal();
      dispatch(updateColorSuccess());
      getColors(token, dispatch);
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      dispatch(updateColorfailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const updateColor = (token, id, body, onCloseModal, dispatch) => {
  dispatch(updateColorRequest());
  axios
    .put(`${COLORS_URL}/${id}`, body, { headers: { "auth-token": token } })
    .then((res) => {
      onCloseModal();
      dispatch(updateColorSuccess());
      getColors(token, dispatch);
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      dispatch(updateColorfailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const deleteColor = (token, id, onCloseDeleteModal, dispatch) => {
  console.log(token);
  dispatch(updateColorRequest());
  axios
    .delete(`${COLORS_URL}/${id}`, {
      headers: { "auth-token": token },
    })
    .then((res) => {
      onCloseDeleteModal();
      dispatch(updateColorSuccess());
      getColors(token, dispatch);
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      dispatch(updateColorfailed());
      toast(err.response.data.message, { type: "error" });
    });
};
