import { Spin } from "antd";
import React from "react";

function LoadingScreen() {
  return (
    <div
      className="absolute top-0 left-0 h-screen w-screen flex justify-center items-center"
      style={{ backgroundColor: "rgba(255,255,255,0.5)" }}
    >
      <Spin size="large" />
    </div>
  );
}

export default LoadingScreen;
