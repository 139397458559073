import { Button, Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addColor, updateColor } from "../../../redux/actions/colorActions";

const ColorModal = ({ toggleModal, onCloseModal }) => {
  const { token } = useSelector((state) => state.admin);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (toggleModal.color) {
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        name: toggleModal.color.name,
        hex: toggleModal.color.hex,
      };
      form.setFieldsValue(fields);
    }
  }, [toggleModal, form]);

  const onAddColor = (values) => {
    if (toggleModal.color) {
      updateColor(token, toggleModal.color._id, values, closeModal, dispatch);
    } else {
      addColor(token, values, closeModal, dispatch);
    }
  };

  const closeModal = () => {
    form.resetFields();
    onCloseModal();
  };

  return (
    <Modal
      open={toggleModal.active}
      onCancel={closeModal}
      footer={null}
      centered
    >
      <div>
        <h2 className="font-bold text-xl mb-6">
          {toggleModal.color
            ? "Modifier la couleur"
            : "Ajouter une nouvelle couleur"}
        </h2>
        <Form
          layout="vertical"
          onFinish={onAddColor}
          variant={"filled"}
          form={form}
        >
          <Form.Item
            label="Nom"
            name="name"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le nom de l'article!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Code hexa"
            name="hex"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le code hexa de la couleur!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={null}>
            <Button type="primary" htmlType="submit" style={{ marginTop: 10 }}>
              {toggleModal.color
                ? "Modifier la couleur"
                : "Ajouter une nouvelle couleur"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ColorModal;
