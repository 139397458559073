import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const EmailModal = ({ toggleModal, onCloseModal }) => {
  const { admin } = useSelector((state) => state.admin);
  const onAddCollection = () => {};

  const closeModal = () => {
    onCloseModal();
  };

  return (
    <Modal open={toggleModal} onCancel={closeModal} footer={null} centered>
      <div>
        <h2 className="font-bold text-xl mb-6">Modifier l'adresse email</h2>
        <Form
          layout="vertical"
          onFinish={onAddCollection}
          variant={"filled"}
          initialValues={{ email: admin.email }}
        >
          <Form.Item
            label="Adresse Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner votre adresse Email!",
              },
              {
                type: "email",
                message:
                  "Veuillez renseigner une adresse Email au format valide!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={null}>
            <Button type="primary" htmlType="submit" style={{ marginTop: 10 }}>
              Modifier l'adresse email
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EmailModal;
