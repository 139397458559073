import React from "react";

const OrdersTemplate = () => {
  return (
    <div className="max-w-[1440px] m-auto p-4">
      <div className="flex justify-between p-4">
        <h1 className="text-2xl font-bold">Commandes en cours</h1>
      </div>
    </div>
  );
};

export default OrdersTemplate;
