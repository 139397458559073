import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboards: [],
    dashboardsLoading: false,
  },
  reducers: {
    getDashboardsRequest: (state) => {
      state.dashboardsLoading = true;
    },
    getDashboardsSuccess: (state, actions) => {
      state.dashboards = actions.payload;
      state.dashboardsLoading = false;
    },
    getDashboardsFailed: (state) => {
      state.dashboardsLoading = false;
    },
  },
});

export const { setScanBarCode } = dashboardSlice.actions;

export default dashboardSlice.reducer;
