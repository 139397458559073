import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button, Image } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { filteredDimension } from "../../../utils/filteredDimension";
import { filteredPrice } from "../../../utils/filteredPrice";
import { filteredWeight } from "../../../utils/filteredWeight";
import LoadingScreen from "../loading-screen/LoadingScreen";

function ArticlesList({ setToggleModal }) {
  const { articles, articlesLoading } = useSelector((state) => state.article);

  return (
    <div className="p-4">
      {articlesLoading && <LoadingScreen />}
      <div>
        <div className="flex border text-xs">
          <div className="p-1 border-r w-[6%]">Image</div>
          <div className="p-1 border-r w-[8%]">Code Barre</div>
          <div className="p-1 border-r w-[10%]">Nom</div>
          <div className="p-1 border-r w-[9%]">Collection</div>
          <div className="p-1 border-r w-[6%]">Prix</div>
          <div className="p-1 border-r w-[8%]">Prix Grossiste</div>
          <div className="p-1 border-r w-[6%]">Quantité</div>
          <div className="p-1 border-r w-[6%]">Couleur</div>
          <div className="p-1 border-r w-[8%]">Matière</div>
          <div className="p-1 border-r w-[11%]">Dimensions</div>
          <div className="p-1 border-r w-[6%]">Poids</div>
          <div className="p-1 border-r w-[10%]">Emplacement</div>
          <div className="p-1 w-[9%]">Actions</div>
        </div>
        {articles.length > 0 ? (
          articles.map((article) => (
            <div
              key={article._id}
              className="flex border-b border-r border-l text-xs"
            >
              <div className="p-1 border-r w-[6%]">
                <Image src={article.images[0]} />
              </div>
              <div className="p-1 border-r w-[8%]">{article.barCode}</div>
              <div className="p-1 border-r w-[10%]">{article?.model?.name}</div>
              <div className="p-1 border-r w-[9%]">
                {article?.articleCollection?.name}
              </div>
              <div className="p-1 border-r w-[6%]">
                {filteredPrice(article.price)}
              </div>
              <div className="p-1 border-r w-[8%]">
                {article.wholesalePrice &&
                  filteredPrice(article.wholesalePrice)}
              </div>
              <div className="p-1 border-r w-[6%]">{article.quantity}</div>
              <div className="p-1 border-r w-[6%]">
                <div className="flex items-center">
                  <span
                    className={`h-[15px] w-[15px] border mr-2 rounded`}
                    style={{ backgroundColor: article.color.hex }}
                  />
                  {article.color.name}
                </div>
                {article.secondColor && (
                  <div className="flex items-center">
                    <span
                      className={`h-[15px] w-[15px] border mr-2 rounded`}
                      style={{ backgroundColor: article.secondColor.hex }}
                    />
                    {article.secondColor.name}
                  </div>
                )}
              </div>
              <div className="p-1 border-r w-[8%]">{article.matter}</div>
              <div className="p-1 border-r w-[11%]">
                {filteredDimension(article.size.length)} x{" "}
                {filteredDimension(article.size.width)}
              </div>
              <div className="p-1 border-r w-[6%]">
                {filteredWeight(article.weight)}
              </div>
              <div className="p-1 border-r w-[10%]">{article.location}</div>
              <div className="p-1 w-[9%] flex items-start">
                <Link
                  to={`/article/${article.barCode}`}
                  style={{
                    fontSize: 14,
                    lineHeight: 1.5714285714285714,
                    height: 24,
                    padding: "0px 7px",
                    borderRadius: 4,
                    backgroundColor: "#273747",
                    color: "white",
                    marginRight: 10,
                  }}
                >
                  <EditFilled />
                </Link>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={() =>
                    setToggleModal({
                      active: true,
                      article: article,
                    })
                  }
                >
                  <DeleteFilled />
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div className="border-b border-r border-l p-2 text-center">
            Vous n'avez pas d'articles
          </div>
        )}
      </div>
    </div>
  );
}

export default ArticlesList;
