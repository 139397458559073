import { createSlice } from "@reduxjs/toolkit";

export const scanSlice = createSlice({
  name: "scan",
  initialState: {
    scanBarCode: "",
    refreshArticle: false,
  },
  reducers: {
    setScanBarCode: (state, action) => {
      state.scanBarCode = action.payload;
      state.refreshArticle = !state.refreshArticle;
    },
  },
});

export const { setScanBarCode } = scanSlice.actions;

export default scanSlice.reducer;
