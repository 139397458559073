import { Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { EmailModal, PasswordModal } from "../../mollecules";

const ProfileInformations = () => {
  const { admin } = useSelector((state) => state.admin);
  const [toggleEmailModal, setToggleEmailModal] = useState(false);
  const [togglePasswordModal, setTogglePasswordModal] = useState(false);
  return (
    <div className="p-4">
      <EmailModal
        toggleModal={toggleEmailModal}
        onCloseModal={() => setToggleEmailModal(false)}
      />
      <PasswordModal
        toggleModal={togglePasswordModal}
        onCloseModal={() => setTogglePasswordModal(false)}
      />
      <div>Email : {admin.email}</div>
      <div className="flex mt-2">
        <div className="mr-4">
          <Button size="small" onClick={() => setToggleEmailModal(true)}>
            Modifier Email
          </Button>
        </div>
        <div>
          <Button size="small" onClick={() => setTogglePasswordModal(true)}>
            Modifier mot de passe
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileInformations;
