import React, { useState } from "react";
import { CollectionDeleteModal, CollectionModal } from "../../mollecules";
import { CollectionsList, CollectionsTopPage } from "../../organisms";

const CollectionsTemplate = () => {
  const [toggleModal, setToggleModal] = useState({
    active: false,
    collection: null,
  });
  const [toggleDeleteModal, setToggleDeleteModal] = useState({
    active: false,
    collection: null,
  });
  const onCloseModal = () => {
    setToggleModal({
      active: false,
      collection: null,
    });
  };
  const onCloseDeleteModal = () => {
    setToggleDeleteModal({
      active: false,
      collection: null,
    });
  };
  return (
    <div className="max-w-[1440px] m-auto p-4">
      <CollectionDeleteModal
        toggleDeleteModal={toggleDeleteModal}
        onCloseDeleteModal={onCloseDeleteModal}
      />
      <CollectionModal toggleModal={toggleModal} onCloseModal={onCloseModal} />
      <CollectionsTopPage setToggleModal={setToggleModal} />
      <CollectionsList
        setToggleModal={setToggleModal}
        setToggleDeleteModal={setToggleDeleteModal}
      />
    </div>
  );
};

export default CollectionsTemplate;
