import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteColor } from "../../../redux/actions/colorActions";

const ColorDeleteModal = ({ toggleDeleteModal, onCloseDeleteModal }) => {
  const { token } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  return (
    <Modal
      open={toggleDeleteModal.active}
      onCancel={onCloseDeleteModal}
      footer={null}
    >
      <h2 className="font-bold text-xl mb-6">Supprimer la color</h2>
      <p>
        Êtes-vous sûr de bien vouloir supprimer la color{" "}
        {toggleDeleteModal?.color?.name}
      </p>
      <div className="mt-6 flex justify-end">
        <Button style={{ marginRight: 10 }} onClick={onCloseDeleteModal}>
          Annuler
        </Button>
        <Button
          type="primary"
          danger
          onClick={() =>
            deleteColor(
              token,
              toggleDeleteModal?.color?._id,
              onCloseDeleteModal,
              dispatch
            )
          }
        >
          Supprimer
        </Button>
      </div>
    </Modal>
  );
};

export default ColorDeleteModal;
