import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import LoadingScreen from "../loading-screen/LoadingScreen";

function CollectionsList({ setToggleModal, setToggleDeleteModal }) {
  const { collections, collectionsLoading } = useSelector(
    (state) => state.collection
  );
  return (
    <div className="p-4">
      {collectionsLoading && <LoadingScreen />}
      <div className="w-[600px]">
        <div className="flex border">
          <div className="p-2 border-r w-[50%]">Nom</div>
          <div className="p-2 w-[50%]">Actions</div>
        </div>
        {collections.length > 0 ? (
          collections.map((collection) => (
            <div
              key={collection._id}
              className="flex border-b border-r border-l"
            >
              <div className="p-2 border-r w-[50%]">{collection.name}</div>
              <div className="p-2 w-[50%] flex items-start">
                <Button
                  onClick={() =>
                    setToggleModal({
                      active: true,
                      collection: collection,
                    })
                  }
                  type="primary"
                  size="small"
                  style={{ marginRight: 10 }}
                >
                  <EditFilled />
                </Button>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={() =>
                    setToggleDeleteModal({
                      active: true,
                      collection: collection,
                    })
                  }
                >
                  <DeleteFilled />
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div className="border-b border-r border-l p-2 text-center">
            Vous n'avez pas de collections
          </div>
        )}
      </div>
    </div>
  );
}

export default CollectionsList;
