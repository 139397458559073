import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./reducers/adminReducer";
import articleReducer from "./reducers/articleReducer";
import collectionReducer from "./reducers/collectionReducer";
import colorReducer from "./reducers/colorReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import orderReducer from "./reducers/orderReducer";
import scanReducer from "./reducers/scanReducer";
import userReducer from "./reducers/userReducer";

export default configureStore({
  reducer: {
    scan: scanReducer,
    article: articleReducer,
    admin: adminReducer,
    collection: collectionReducer,
    color: colorReducer,
    user: userReducer,
    order: orderReducer,
    dashboard: dashboardReducer,
  },
});
