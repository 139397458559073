import { UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addOneArticle,
  removeOneArticle,
} from "../../../redux/actions/articleActions";

function Header() {
  const [scanMode, setMode] = useState("");
  const { token } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isScanning, setIsScanning] = useState(false);

  useEffect(() => {
    let buffer = "";
    let timeout;

    const handleKeyDown = (event) => {
      if (!isScanning) setIsScanning(true);

      if (event.keyCode === 16) {
        return;
      }
      if (event.key === "Enter") {
        if (/^8/.test(buffer)) {
          if (scanMode === "add") {
            addOneArticle(token, buffer, dispatch);
          } else if (scanMode === "remove") {
            removeOneArticle(token, buffer, dispatch);
          } else {
            console.log(buffer);
            navigate(`/`);
            navigate(`/article/${buffer}`);
          }
        } else {
          return toast("Le code-barre doit commencer par 8!", {
            type: "error",
          });
        }
        buffer = "";
        setIsScanning(false);
        return;
      }

      buffer += event.key;

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        buffer = "";
        setIsScanning(false);
      }, 500);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    }; // eslint-disable-next-line
  }, [isScanning, navigate]);

  const onSelectMode = (value) => {
    setMode(value);
  };

  return (
    <>
      <header className="bg-[black] min-h-20 text-white">
        <div className="max-w-[1440px] m-auto h-full">
          <div className="flex justify-between h-full p-2">
            <div></div>
            <div className="h-full">
              <nav className="h-full mt-1 mb-2">
                <ul className="flex items-center h-full">
                  <li>
                    <Link className="p-4" to="/">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link className="p-4" to="/orders">
                      Commandes
                    </Link>
                  </li>
                  <li>
                    <Link className="p-4" to="/users">
                      Utilisateurs
                    </Link>
                  </li>
                  <li>
                    <Link className="p-4" to="/collections">
                      Collections
                    </Link>
                  </li>
                  <li>
                    <Link className="p-4" to="/articles">
                      Articles
                    </Link>
                  </li>
                  <li>
                    <Link className="p-4" to="/colors">
                      Couleurs
                    </Link>
                  </li>
                  <li className="border rounded-full h-[25px] w-[25px] ml-4 flex justify-center items-center">
                    <Link to="/profile">
                      <UserOutlined />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="p-4">
              <Button
                type={scanMode === "" ? "primary" : "default"}
                onClick={() => onSelectMode("")}
              >
                Afficher l'article
              </Button>
            </div>
            <div className="p-4">
              <Button
                type={scanMode === "add" ? "primary" : "default"}
                onClick={() => onSelectMode("add")}
              >
                Ajouter 1 article
              </Button>
            </div>
            <div className="p-4">
              <Button
                type={scanMode === "remove" ? "primary" : "default"}
                onClick={() => onSelectMode("remove")}
              >
                Retirer 1 article
              </Button>
            </div>
          </div>
        </div>
        {scanMode && (
          <div className="p-2 bg-[green] text-center text-white font-bold">
            {scanMode === "add"
              ? "Ajouter 1 article"
              : scanMode === "remove" && "Retirer 1 article"}
          </div>
        )}
      </header>
    </>
  );
}

export default Header;
