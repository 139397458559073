const api = `${process.env.REACT_APP_API}/admin`;

export const ARTICLES_URL = `${api}/articles`;
export const ARTICLE_IMAGES_URL = `${api}/article-image`;
export const ARTICLE_ADD_ONE_URL = `${api}//articles-add-one`;
export const ARTICLE_REMOVE_ONE_URL = `${api}//articles-remove-one`;
export const LOGIN_ADMIN_URL = `${api}/login-admin`;
export const GET_ADMIN_URL = `${api}/get-admin`;
export const COLLECTIONS_URL = `${api}/collections`;
export const COLORS_URL = `${api}/colors`;
