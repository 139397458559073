import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import LoadingScreen from "../loading-screen/LoadingScreen";

function ColorsList({ setToggleModal, setToggleDeleteModal }) {
  const { colors, colorsLoading } = useSelector((state) => state.color);
  return (
    <div className="p-4">
      {colorsLoading && <LoadingScreen />}
      <div className="w-[600px]">
        <div className="flex border">
          <div className="p-2 border-r w-1/3">Nom</div>
          <div className="p-2 border-r w-1/3">Code hexa</div>
          <div className="p-2 w-1/3">Actions</div>
        </div>
        {colors.length > 0 ? (
          colors.map((color) => (
            <div key={color._id} className="flex border-b border-r border-l">
              <div className="p-2 border-r w-1/3 flex items-center">
                <div
                  className={`h-[20px] w-[20px] border mr-2 rounded`}
                  style={{ backgroundColor: color.hex }}
                />
                {color.name}
              </div>
              <div className="p-2 border-r w-1/3 flex items-center">
                {color.hex}
              </div>
              <div className="p-2 w-1/3 flex items-start">
                <Button
                  onClick={() =>
                    setToggleModal({
                      active: true,
                      color: color,
                    })
                  }
                  type="primary"
                  size="small"
                  style={{ marginRight: 10 }}
                >
                  <EditFilled />
                </Button>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={() =>
                    setToggleDeleteModal({
                      active: true,
                      color: color,
                    })
                  }
                >
                  <DeleteFilled />
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div className="border-b border-r border-l p-2 text-center">
            Vous n'avez pas de couleurs
          </div>
        )}
      </div>
    </div>
  );
}

export default ColorsList;
