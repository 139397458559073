import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    token: null,
    admin: null,
    adminLoading: false,
  },
  reducers: {
    loginAdminRequest: (state) => {
      state.adminLoading = true;
    },
    loginAdminSuccess: (state, actions) => {
      state.adminLoading = false;
      state.admin = actions.payload.admin;
      state.token = actions.payload.token;
      localStorage.setItem("token", actions.payload.token);
    },
    loginAdminFailed: (state) => {
      state.adminLoading = false;
    },
  },
});

export const { loginAdminRequest, loginAdminSuccess, loginAdminFailed } =
  adminSlice.actions;

export default adminSlice.reducer;
