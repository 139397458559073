import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoadingScreen } from "../components/organisms";
import { Header } from "../components/templates";
import {
  Article,
  Articles,
  Collections,
  Color,
  Home,
  Login,
  Orders,
  Profile,
  Users,
} from "../pages";
import { getAdmin } from "../redux/actions/adminActions";
import { getArticles } from "../redux/actions/articleActions";
import { getCollections } from "../redux/actions/collectionActions";
import { getColors } from "../redux/actions/colorActions";

function Navigation() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.admin);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const clearLoading = () => setLoading(false);
    const fetchData = () => {
      const tokenFromStorage = localStorage.getItem("token");

      if (tokenFromStorage) {
        const body = {
          token: tokenFromStorage,
        };
        getAdmin(body, clearLoading, dispatch);
        getArticles(tokenFromStorage, dispatch);
        getCollections(tokenFromStorage, dispatch);
        getColors(tokenFromStorage, dispatch);
      } else {
        clearLoading();
      }
    };

    fetchData();
  }, [dispatch, token]);
  return loading ? (
    <LoadingScreen />
  ) : token ? (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/collections" element={<Collections />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/article" element={<Article />} />
        <Route path="/article/:barCode" element={<Article />} />
        <Route path="/colors" element={<Color />} />
        <Route path="/users" element={<Users />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;
