import React, { useState } from "react";
import { ColorDeleteModal, ColorModal } from "../../mollecules";
import { ColorsList, ColorsTopPage } from "../../organisms";

const ColorsTemplate = () => {
  const [toggleModal, setToggleModal] = useState({
    active: false,
    color: null,
  });
  const [toggleDeleteModal, setToggleDeleteModal] = useState({
    active: false,
    color: null,
  });
  const onCloseModal = () => {
    setToggleModal({
      active: false,
      color: null,
    });
  };
  const onCloseDeleteModal = () => {
    setToggleDeleteModal({
      active: false,
      color: null,
    });
  };
  return (
    <div className="max-w-[1440px] m-auto p-4">
      <ColorDeleteModal
        toggleDeleteModal={toggleDeleteModal}
        onCloseDeleteModal={onCloseDeleteModal}
      />
      <ColorModal toggleModal={toggleModal} onCloseModal={onCloseModal} />
      <ColorsTopPage setToggleModal={setToggleModal} />
      <ColorsList
        setToggleModal={setToggleModal}
        setToggleDeleteModal={setToggleDeleteModal}
      />
    </div>
  );
};

export default ColorsTemplate;
