import { Button, Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCollection,
  updateCollection,
} from "../../../redux/actions/collectionActions";

const CollectionModal = ({ toggleModal, onCloseModal }) => {
  const { token } = useSelector((state) => state.admin);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (toggleModal.collection) {
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        name: toggleModal.collection.name,
      };
      form.setFieldsValue(fields);
    }
  }, [toggleModal, form]);

  const onAddCollection = (values) => {
    if (toggleModal.collection) {
      updateCollection(
        token,
        toggleModal.collection._id,
        values,
        closeModal,
        dispatch
      );
    } else {
      addCollection(token, values, closeModal, dispatch);
    }
  };

  const closeModal = () => {
    form.resetFields();
    onCloseModal();
  };

  return (
    <Modal
      open={toggleModal.active}
      onCancel={closeModal}
      footer={null}
      centered
    >
      <div>
        <h2 className="font-bold text-xl mb-6">
          {toggleModal.collection
            ? "Modifier la collection"
            : "Ajouter une nouvelle collection"}
        </h2>
        <Form
          layout="vertical"
          onFinish={onAddCollection}
          variant={"filled"}
          form={form}
        >
          <Form.Item
            label="Nom"
            name="name"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le nom de l'article!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={null}>
            <Button type="primary" htmlType="submit" style={{ marginTop: 10 }}>
              {toggleModal.collection
                ? "Modifier la collection"
                : "Ajouter une nouvelle collection"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CollectionModal;
