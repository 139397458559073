import axios from "axios";
import { toast } from "react-toastify";
import { GET_ADMIN_URL, LOGIN_ADMIN_URL } from "../api/api";
import {
  loginAdminFailed,
  loginAdminRequest,
  loginAdminSuccess,
} from "../reducers/adminReducer";

export const loginAdmin = (body, dispatch) => {
  dispatch(loginAdminRequest());
  axios
    .post(LOGIN_ADMIN_URL, body)
    .then((res) => {
      dispatch(loginAdminSuccess(res.data));
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(loginAdminFailed());
    });
};

export const getAdmin = (body, clearLoading, dispatch) => {
  dispatch(loginAdminRequest());
  axios
    .post(GET_ADMIN_URL, body)
    .then((res) => {
      dispatch(loginAdminSuccess(res.data));
      clearLoading();
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(loginAdminFailed());
      clearLoading();
    });
};
