import React from "react";
import { Link } from "react-router-dom";

function ArticlesTopPage() {
  return (
    <div className="flex justify-between p-4">
      <h1 className="text-2xl font-bold">Tout nos articles</h1>

      <Link
        to={`/article`}
        style={{
          backgroundColor: "#273747",
          color: "white",
          padding: "5px 10px",
          borderRadius: 5,
        }}
      >
        Ajouter un article
      </Link>
    </div>
  );
}

export default ArticlesTopPage;
