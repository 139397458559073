import { createSlice } from "@reduxjs/toolkit";

export const colorSlice = createSlice({
  name: "color",
  initialState: {
    colors: [],
    colorsLoading: false,
    colorLoading: false,
  },
  reducers: {
    getColorsRequest: (state) => {
      state.colorsLoading = true;
    },
    getColorsSuccess: (state, actions) => {
      state.colors = actions.payload;
      state.colorsLoading = false;
    },
    getColorsfailed: (state) => {
      state.colorsLoading = false;
    },
    updateColorRequest: (state) => {
      state.colorLoading = true;
    },
    updateColorSuccess: (state, actions) => {
      state.colorLoading = false;
    },
    updateColorfailed: (state) => {
      state.colorLoading = false;
    },
  },
});

export const {
  getColorsRequest,
  getColorsSuccess,
  getColorsfailed,
  updateColorRequest,
  updateColorSuccess,
  updateColorfailed,
} = colorSlice.actions;

export default colorSlice.reducer;
