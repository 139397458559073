import axios from "axios";
import { toast } from "react-toastify";
import {
  ARTICLE_ADD_ONE_URL,
  ARTICLE_IMAGES_URL,
  ARTICLE_REMOVE_ONE_URL,
  ARTICLES_URL,
} from "../api/api";
import {
  getArticlesFailed,
  getArticlesRequest,
  getArticlesSuccess,
  updateArticleFailed,
  updateArticleRequest,
  updateArticleSuccess,
} from "../reducers/articleReducer";
import { setScanBarCode } from "../reducers/scanReducer";

export const getArticles = (token, dispatch) => {
  dispatch(getArticlesRequest());
  axios
    .get(`${ARTICLES_URL}`, { headers: { "auth-token": token } })
    .then((res) => {
      dispatch(getArticlesSuccess(res.data));
    })
    .catch(() => {
      dispatch(getArticlesFailed());
    });
};

export const addArticleImages = (token, body) => {
  return axios.post(ARTICLE_IMAGES_URL, body, {
    headers: { "auth-token": token },
  });
};

export const addArticle = (token, body, goToArticle, dispatch) => {
  dispatch(updateArticleRequest());
  axios
    .post(ARTICLES_URL, body, {
      headers: { "auth-token": token },
    })
    .then((res) => {
      dispatch(updateArticleSuccess());
      toast(res.data.message, { type: "success" });
      getArticles(token, dispatch);
      goToArticle();
    })
    .catch((err) => {
      dispatch(updateArticleFailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const getArticle = (token, barCode) => {
  return axios.get(`${ARTICLES_URL}/${barCode}`, {
    headers: { "auth-token": token },
  });
};

export const updateArticle = (token, barCode, body, goToArticle, dispatch) => {
  dispatch(updateArticleRequest());
  return axios
    .put(`${ARTICLES_URL}/${barCode}`, body, {
      headers: { "auth-token": token },
    })
    .then((res) => {
      getArticles(token, dispatch);
      dispatch(updateArticleSuccess());
      toast(res.data.message, { type: "success" });
      goToArticle();
    })
    .catch((err) => {
      dispatch(updateArticleFailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const deleteArticle = (token, barCode, dispatch) => {
  dispatch(updateArticleRequest());
  axios
    .delete(`${ARTICLES_URL}/${barCode}`, { headers: { "auth-token": token } })
    .then((res) => {
      getArticles(token, dispatch);
      dispatch(updateArticleSuccess());
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      dispatch(updateArticleFailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const addOneArticle = (token, barCode, dispatch) => {
  axios
    .post(`${ARTICLE_ADD_ONE_URL}/${barCode}`, null, {
      headers: { "auth-token": token },
    })
    .then((res) => {
      dispatch(setScanBarCode(barCode));
      getArticles(token, dispatch);
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
    });
};

export const removeOneArticle = (token, barCode, dispatch) => {
  axios
    .post(`${ARTICLE_REMOVE_ONE_URL}/${barCode}`, null, {
      headers: { "auth-token": token },
    })
    .then((res) => {
      dispatch(setScanBarCode(barCode));
      getArticles(token, dispatch);
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
    });
};
